import * as types from '../types';
import AccountService from '@/services/accountService';

const state = {
	// 用户登录信息
	userInfo: JSON.parse(localStorage.getItem('userInfo')) || {},
	// 用户数据信息
	userData: {},
	// 量化管理用户信息
	reportUserData: {},
	// 量化管理运动问题
	sportsDesc: {},
	// 量化管理营养数据
	yyAllData: {},
	// 量化管理摄入数据
	quantitative_sr: 0,
	// 量化管理消耗数据
	quantitative_xh: 0,
	// 选择按钮是否显示
	selectVisible: true,
	// 缓存疾病信息
	cacheProject: [],
	// 饮食习惯
	habits: [],
	// 量化评价分数
	quantitative_score: 0,
	// 调整方案评分
	adjustment_score: 0,
	// 运动评分
	sport_score: 0,
	// 运动频次
	adjustment_count: 1,
	// 运动场地
	adjustment_site: '',
	// 运动作业
	adjustment_suggestion: '',
	adjustment_diease: '',
	// 营养作业
	noruishment_suggestion: '',
	noruishment_diease: '',
	// 下次交作业日期
	nextWorkDate: '',
	// 运动开始时间
	start_time: '',
	// 血糖编辑状态
	sugarEditable: true,
	// 血糖编辑数据
	sugarData: '',
	// 血糖评价分析与指导
	sugarRate: '',
	// 血糖日期
	sugarDate: '',
	// 五点血糖数据
	sugarFiveData: [],
	// 周运动评价填写数据
	weekFillData: '',
	// 调整方案填写数据
	adjustmentFillData: '',
};

const actions = {
	// 用户登录
	setUserInfo({ commit }, response) {
		localStorage.setItem('userInfo', JSON.stringify(response));
		commit(types.SET_USER_INFO, response);
	},
	// 请求用户信息
	getUserData({ commit }, id) {
		AccountService.getInfo(id).then((data) => {
			commit(types.GET_USER_DATA, data.user_info);
		});
	},
	setReportUserData({ commit }, data) {
		commit(types.REPORT_USER_DATA, data);
	},
	setSportDesc({ commit }, data) {
		commit(types.SPORT_DESC, data);
	},
	setYYAllData({ commit }, data) {
		commit(types.YY_ALL_DATA, data);
	},
	setSugarData({ commit }, data) {
		commit(types.SUGAR_DATA, data);
	},
	setSugarFiveData({ commit }, data) {
		console.log(data);
		commit(types.SUGAR_FIVE_DATA, data);
	},
};

const getters = {
	userInfo: (state) => state.userInfo.user_info,
	getUserData: (state) => state.userData,
	reportUserData: (state) => state.reportUserData,
	sportsDesc: (state) => state.sportsDesc,
	yyAllData: (state) => state.yyAllData,
	quantitative_sr: (state) => state.quantitative_sr,
	quantitative_xh: (state) => state.quantitative_xh,
	selectVisible: (state) => state.selectVisible,
	cacheProject: (state) => state.cacheProject,
	habits: (state) => state.habits,
	quantitative_score: (state) => state.quantitative_score,
	adjustment_score: (state) => state.adjustment_score,
	adjustment_count: (state) => state.adjustment_count,
	adjustment_site: (state) => state.adjustment_site,
	adjustment_suggestion: (state) => state.adjustment_suggestion,
	adjustment_diease: (state) => state.adjustment_diease,
	noruishment_suggestion: (state) => state.noruishment_suggestion,
	noruishment_diease: (state) => state.noruishment_diease,
	nextWorkDate: (state) => state.nextWorkDate,
	sport_score: (state) => state.sport_score,
	start_time: (state) => state.start_time,
	sugarEditable: (state) => state.sugarEditable,
	sugarData: (state) => state.sugarData,
	sugarRate: (state) => state.sugarRate,
	sugarDate: (state) => state.sugarDate,
	sugarFiveData: (state) => state.sugarFiveData,
	weekFillData: (state) => state.weekFillData,
	adjustmentFillData: (state) => state.adjustmentFillData,
};

const mutations = {
	[types.SET_USER_INFO](state, response) {
		state.userInfo = response;
	},
	[types.GET_USER_DATA](state, response) {
		state.userData = response;
	},
	[types.REPORT_USER_DATA](state, response) {
		state.reportUserData = response;
	},
	[types.SPORT_DESC](state, response) {
		state.sportsDesc = response;
	},
	[types.YY_ALL_DATA](state, response) {
		state.yyAllData = response;
	},
	[types.SUGAR_DATA](state, response) {
		state.sugarData = response;
	},
	[types.SUGAR_FIVE_DATA](state, response) {
		state.sugarFiveData = response;
	},
	setQuantitativeSr(state, response) {
		state.quantitative_sr = response;
	},
	setQuantitativeXh(state, response) {
		console.log(response);
		state.quantitative_xh = response;
	},
	setSelectVisible(state, response) {
		state.selectVisible = response;
	},
	setCacheProject(state, response) {
		state.cacheProject = response;
	},
	setHabits(state, response) {
		state.habits = response;
	},
	setQuantitativeScore(state, response) {
		state.quantitative_score = response;
	},
	setAdjustmentScore(state, response) {
		state.adjustment_score = response;
	},
	setAdjustmentSite(state, response) {
		state.adjustment_site = response;
	},
	setAdjustmentCount(state, response) {
		state.adjustment_count = response;
	},
	setAdjustmentSuggestion(state, response) {
		state.adjustment_suggestion = response;
	},
	setAdjustmentDiease(state, response) {
		state.adjustment_diease = response;
	},
	setNorishmentSuggestion(state, response) {
		state.noruishment_suggestion = response;
	},
	setNorishmentDiease(state, response) {
		state.noruishment_diease = response;
	},
	setNextWorkDate(state, response) {
		state.nextWorkDate = response;
	},
	setSportScore(state, response) {
		state.sport_score = response;
	},
	setStartTime(state, response) {
		state.start_time = response;
	},
	setSugarEditable(state, response) {
		state.sugarEditable = response;
	},
	setSugarRate(state, response) {
		state.sugarRate = response;
	},
	setSugarDate(state, response) {
		state.sugarDate = response;
	},
	setWeekFillData(state, response) {
		console.log('week', response);
		state.weekFillData = response;
	},
	setAdjustmentFillData(state, response) {
		console.log('adjustment', response);
		state.adjustmentFillData = response;
	},
};

export default {
	state,
	actions,
	getters,
	mutations,
};
