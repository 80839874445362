<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
	name: 'App',
};
</script>

<style>
.el-loading-mask {
  background-color: rgba(0, 0, 0, 0.2) !important;
}
</style>
