import Vue from 'vue';
import App from './App.vue';
import VueRouter from 'vue-router';
import ViewUI from 'view-design';
import { Loading, Table, TableColumn, Select, Option, Input, InputNumber } from 'element-ui';
import routes from './router';
import store from './vuex/store';
import './assets/css/reset.css';
import './assets/css/base.less';
import './mytheme/index.less';
import 'element-ui/lib/theme-chalk/index.css';

Vue.use(VueRouter);
Vue.use(ViewUI);
Vue.use(Loading);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Select);
Vue.use(Option);
Vue.use(Input);
Vue.use(InputNumber);

const router = new VueRouter({
	routes,
	linkActiveClass: 'active',
});

// 登录中间验证，页面需要登录而没有登录的情况直接跳转登录
router.beforeEach((to, from, next) => {
	if (to.matched.some((record) => record.meta.requiresAuth)) {
		if (localStorage.getItem('userInfo')) {
			next();
		} else {
			next({
				path: '/login',
				query: { redirect: to.fullPath },
			});
		}
	} else {
		next();
	}
});

new Vue({
	router,
	store,
	render: (h) => h(App),
}).$mount('#app');
