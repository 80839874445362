// user 用户
export const SET_USER_INFO = 'SET_USER_INFO';
export const GET_USER_DATA = 'GET_USER_DATA';
export const REPORT_USER_DATA = 'REPORT_USER_DATA';
export const SPORT_DESC = 'SPORT_DESC';
export const YY_ALL_DATA = 'YY_ALL_DATA';
export const QUANTITATIVE_SR = 'QUANTITATIVE_SR';
export const QUANTITATIVE_XH = 'QUANTITATIVE_XH';
export const SUGAR_DATA = 'SUGAR_DATA';
export const SUGAR_FIVE_DATA = 'SUGAR_FIVE_DATA';
